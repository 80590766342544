$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

$font-color: $gray-500;

.footer {
  background-color: $primary;
  padding: 2rem 0 4rem 0;
  color: $font-color;

  @include media-breakpoint-up(lg) {
    padding: 2rem 0;
  }

  a {
    color: $font-color;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  .title {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  .socialLink {
    width: 45px;
    display: inline-block;
    margin-right: 1rem;

    .icon {
      @include icon-size(2.7);

      fill: $font-color;

      &:hover {
        fill: white;
      }
    }
  }

  .divider {
    border-top: 1px solid $font-color;
    margin: 1rem 0;
  }

  .trustLogos {
    a {
      display: inline-block;
      margin-left: 1rem;
    }
    img {
      opacity: 0.85;
      max-height: 50px !important;
      width: auto;

      &:hover {
        opacity: 1;
      }
    }
  }
}
